<template>
  <div class="add-music">
    <el-form
      :model="musicForm"
      :rules="musicRules"
      ref="musicForm"
      class="demo-musicForm"
      hide-required-asterisk
      label-width="100px"
      label-position="left"
    >
      <el-form-item label="音乐名称" prop="musicName">
        <el-input
          v-model="musicForm.musicName"
          placeholder="请输入音乐名称"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="演唱人" prop="musicSinger">
        <el-input
          v-model="musicForm.musicSinger"
          placeholder="请输入演唱人"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="音乐封面" prop="musicCover">
        <upload-img
          :fixedNumber="[20, 20]"
          fixedName="1:1"
          accept="image/*"
          tip="推荐图片尺寸宽高比1:1"
          uploadName="上传封面"
          @uploadInfo="uploadInfo"
          :fixedFlag="true"
          ref="cover"
        />
      </el-form-item>
      <el-form-item label="上传音乐" prop="musicUrl">
        <upload-img
          accept="audio/*"
          tip="MP3格式"
          @uploadInfo="uploadAudio"
          ref="audio"
        />
      </el-form-item>
      <!-- <el-form-item label="音乐时长" prop="audioTime">
        <el-input
          v-model="musicForm.audioTime"
          placeholder="上传时自动获取"
          disabled
          class="text-input"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="设置星级" class="star-form">
        <el-rate
          v-model="musicForm.starLevel"
          :colors="['#5E83F5', '#5E83F5', '#5E83F5']"
        >
        </el-rate>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)" class="gray-btn">取消</el-button>
        <el-button
          type="primary"
          @click="submitForm('musicForm')"
          class="blue-btn"
          :loading="submitLoading"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImg from "../../../components/UploadImg";
import { editMusic } from "@/api/rhythm";
export default {
  components: { UploadImg },
  data() {
    return {
      musicForm: {
        musicName: "",
        musicSinger: "",
        musicCover: "",
        musicUrl: "",
        totalPoints: "",
        starLevel: 1,
      },
      musicRules: {
        musicName: [
          { required: true, message: "请输入音乐名称", trigger: "blur" },
        ],
        musicSinger: [
          { required: true, message: "请输入音乐演唱人", trigger: "blur" },
        ],
        musicCover: [
          { required: true, message: "请上传音乐封面", trigger: "change" },
        ],
        musicUrl: [
          { required: true, message: "请上传音乐", trigger: "change" },
        ],
      },
      submitLoading: false,
    };
  },
  created() {
    console.log("this.$route.query.row :>> ", this.$route.query);
    this.musicForm = JSON.parse(this.$route.query.row);
  },
  mounted() {
    this.$nextTick(() => {
      console.log("object :>> ", this.$refs);
      this.$refs.cover.init("resultImg", this.musicForm.musicCover);
      this.$refs.audio.init("resultFile", this.musicForm.musicUrl);
    });
  },
  methods: {
    // 获取上传封面
    uploadInfo(info) {
      // console.log("info :>> ", info);
      this.musicForm.musicCover = info;
    },
    // 获取上传音乐
    uploadAudio(info) {
      this.musicForm.musicUrl = info;
      let audioElement = new Audio(info);
      audioElement.addEventListener("loadedmetadata", () => {
        const audioDuration = audioElement.duration;
        // console.log(audioDuration);
        // const m = Math.floor(audioDuration / 60);
        // const s = Math.floor(audioDuration % 60);
        this.musicForm.musicDuration = audioDuration;
        // this.musicForm.audioTime = `${m < 10 ? "0" + m : m}:${
        //   s < 10 ? "0" + s : s
        // }`;
      });
    },
    // 修改音乐
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          const {
            id,
            musicCover,
            musicDuration,
            musicName,
            musicSinger,
            musicUrl,
            starLevel,
          } = this.musicForm;
          const param = {
            id,
            musicCover,
            musicDuration,
            musicName,
            musicSinger,
            musicUrl,
            starLevel,
          };
          await editMusic(param);
          this.$message.success("修改成功！");
          this.$router.go(-1);
          // this.musicForm.quote.forEach((el) => {
          //   el.list.forEach((item) => {
          //     item.amount = Number(item.amount) * 100;
          //     item.title = this.getDictDataLabel(
          //       this.DICT_TYPE.QUOTATION_PRICE,
          //       item.type
          //     );
          //   });
          // });
          // if (this.actionType === "add") {
          //   addQuote(this.musicForm).then(() => {
          //     this.$message.success("新建报价单成功");
          //     this.$router.push("/quotation");
          //   });
          // } else {
          //   this.musicForm.id = this.editId;
          //   editQuote(this.musicForm).then(() => {
          //     this.$message.success("修改报价单成功");
          //     this.$router.push("/quotation");
          //   });
          // }
          // setTimeout(() => {
          //   this.submitLoading = false;
          // }, 1000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.push("/addMusic");
    },
  },
};
</script>
<style lang="scss" scoped>
.add-music {
  .text-input {
    width: 200px;
  }

  ::v-deep .star-form {
    .el-rate {
      height: 32px;
      line-height: 32px;
      border-radius: 2px;
      border: 1px solid #dbdfdf;
      width: 200px;
      text-align: center;
      .el-rate__item {
        .el-rate__icon {
          font-size: 28px;
        }
        // .el-rate__decimal {
        //   color: #ce3535; /* 修改为需要的颜色 */
        // }
      }
    }
  }
}
</style>
